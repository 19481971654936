import React, { useEffect, useState } from 'react';
import SearchField from '../../elements/SearchField';
import { UiConstants } from '../constants';
import './ConsoleAreaConsoleSetAssignmentDataView.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/Dialog';
import Pagination from '../components/Pagination';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import { Switch } from '../../elements/Switch';
import {
  LockingConsoleWithOptionalId,
  Series,
  System,
} from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  changeLockingConsole,
  createLockingConsole,
  updateEditedLockingConsole,
} from '../../redux/admin/adminFacadeActions';
import TableRow from '../elements/TableRow';
import TableHeader from '../elements/TableHeader';
import Table from '../components/Table';
import {
  AssemblyOnFrame,
  AssemblyType,
  RangeOfApplication,
} from '../../redux/constants';
import { AnyAction } from 'redux';
import { useAdminSearch } from '../hooks';
import { AssignSelectGroup } from '../components/AssignSelectGroup';
import InputField from '../../elements/InputField';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';
import AdminRadioButton from '../../elements/RadioButton';
import CheckBox, { updateCheckBoxValue } from '../../elements/CheckBox';
import { conditionalValue, fieldsFilled } from '../general/helpers';

interface AdminLockingConsoleDialogContentProps {
  rangeOfApplication: RangeOfApplication;
}

function AdminLockingConsoleDialogContent(
  props: AdminLockingConsoleDialogContentProps,
) {
  const editedLockingConsole = useSelector<
    AdminState,
    LockingConsoleWithOptionalId
  >(state => state.adminFacade.editedLockingConsole!);

  const selectedSystemIds =
    editedLockingConsole?.systems.map(system => system.id) || [];
  const [allSystems, allSeries] = useSelector<AdminState, [System[], Series[]]>(
    state => {
      return [
        state.adminFacade.systems,
        (props.rangeOfApplication === RangeOfApplication.FACADE
          ? state.adminFacade
          : state.adminRoof
        ).series.filter(series => selectedSystemIds.includes(series.system.id)),
      ];
    },
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function isAssemblyTypeFrameInward() {
    return editedLockingConsole.assemblyTypesInward.includes(
      AssemblyType.ASSEMBLY_TYPE_FRAME,
    );
  }

  function isAssemblyTypeSashInward() {
    return editedLockingConsole.assemblyTypesInward.includes(
      AssemblyType.ASSEMBLY_TYPE_SASH,
    );
  }

  function isAssemblyTypeFrameOutward() {
    return editedLockingConsole.assemblyTypesOutward.includes(
      AssemblyType.ASSEMBLY_TYPE_FRAME,
    );
  }

  function isAssemblyTypeSashOutward() {
    return editedLockingConsole.assemblyTypesOutward.includes(
      AssemblyType.ASSEMBLY_TYPE_SASH,
    );
  }

  if (!editedLockingConsole) {
    return <></>;
  }

  function updateEditedConsolePropertyAction<
    Key extends keyof LockingConsoleWithOptionalId,
  >(key: Key) {
    return (value: LockingConsoleWithOptionalId[Key]) =>
      dispatch(
        updateEditedLockingConsole({ ...editedLockingConsole, [key]: value }),
      );
  }

  return (
    <div>
      <FormLayout>
        <FormLayoutElement
          rowStart={1}
          columnStart={1}
          columnWidth={2}
          rowHeight={1}
        >
          <AssignSelectGroup
            multipleSelectAllowed={true}
            headings={{
              componentHeadingWithoutDialog: 'Profilsysteme *',
              headingLeftTable: 'nicht ausgewählt',
              headingRightTable: 'ausgewählt',
            }}
            addElements={(systems: System[]) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  systems: [...editedLockingConsole.systems, ...systems],
                }),
              )
            }
            removeElements={(systems: System[]) => {
              const filteredSystems = editedLockingConsole.systems.filter(
                system => !systems.includes(system),
              );
              const filteredSystemsIds = filteredSystems.map(
                filteredSystem => filteredSystem.id,
              );
              const filteredSeries = editedLockingConsole.series.filter(
                series => filteredSystemsIds.includes(series.system.id),
              );
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  systems: filteredSystems,
                  series: filteredSeries,
                }),
              );
            }}
            selectedElements={editedLockingConsole.systems!}
            deselectedElements={allSystems.filter(
              f =>
                !editedLockingConsole
                  .systems!.map(f2 => f2.name)
                  .includes(f.name),
            )}
            displayNameExtractor={(f: System) => f.name}
            backgroundWhite={true}
            disabled={editedLockingConsole.withoutSystem}
          />
        </FormLayoutElement>

        <FormLayoutElement
          rowStart={1}
          columnStart={3}
          columnWidth={2}
          rowHeight={1}
        >
          <AssignSelectGroup
            componentClass="console-set-data-view__console-set-properties-data-series"
            multipleSelectAllowed={true}
            headings={{
              componentHeadingWithoutDialog: 'Profilserien *',
              headingLeftTable: 'nicht ausgewählt',
              headingRightTable: 'ausgewählt',
            }}
            addElements={(series: Series[]) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  series: [...editedLockingConsole.series, ...series],
                }),
              )
            }
            removeElements={(seriesToRemove: Series[]) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  series: editedLockingConsole.series.filter(
                    series => !seriesToRemove.includes(series),
                  ),
                }),
              )
            }
            selectedElements={editedLockingConsole.series!}
            deselectedElements={allSeries.filter(
              f =>
                !editedLockingConsole
                  .series!.map(f2 => f2.name)
                  .includes(f.name),
            )}
            displayNameExtractor={(f: Series) => f.name}
            backgroundWhite={true}
            disabled={editedLockingConsole.withoutSystem}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={1}>
          <Switch
            label="Universalkonsole"
            turnedOn={editedLockingConsole.withoutSystem}
            onChange={(withoutSystem: boolean) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  withoutSystem,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1} columnWidth={1}>
          <InputField
            label="Artikelnummer *"
            placeholder="z.B. 42.424.42"
            value={editedLockingConsole.artNr || ''}
            additionalClass="consoles-data-dialog__art-nr"
            onChange={(artNr: string) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  artNr,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={2} columnWidth={3}>
          <InputField
            label="Bezeichnung *"
            placeholder="z.B. D&H's beste"
            value={editedLockingConsole.name || ''}
            additionalClass="consoles-data-dialog__name"
            onChange={(name: string) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  name,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={1}>
          <FormLayoutSubgroupTitle>
            Montageposition einwärts *
          </FormLayoutSubgroupTitle>
          <CheckBox
            label="Rahmenmontage"
            checked={isAssemblyTypeFrameInward()}
            onClick={updateCheckBoxValue(
              updateEditedConsolePropertyAction('assemblyTypesInward'),
              AssemblyType.ASSEMBLY_TYPE_FRAME,
              editedLockingConsole.assemblyTypesInward,
            )}
          />
          <CheckBox
            label="Flügelmontage"
            checked={isAssemblyTypeSashInward()}
            onClick={updateCheckBoxValue(
              updateEditedConsolePropertyAction('assemblyTypesInward'),
              AssemblyType.ASSEMBLY_TYPE_SASH,
              editedLockingConsole.assemblyTypesInward,
            )}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={2} columnWidth={1}>
          <FormLayoutSubgroupTitle>
            Montageposition auswärts *
          </FormLayoutSubgroupTitle>
          <CheckBox
            label="Rahmenmontage"
            checked={isAssemblyTypeFrameOutward()}
            onClick={updateCheckBoxValue(
              updateEditedConsolePropertyAction('assemblyTypesOutward'),
              AssemblyType.ASSEMBLY_TYPE_FRAME,
              editedLockingConsole.assemblyTypesOutward,
            )}
          />
          <CheckBox
            label="Flügelmontage"
            checked={isAssemblyTypeSashOutward()}
            onClick={updateCheckBoxValue(
              updateEditedConsolePropertyAction('assemblyTypesOutward'),
              AssemblyType.ASSEMBLY_TYPE_SASH,
              editedLockingConsole.assemblyTypesOutward,
            )}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={3} columnWidth={1}>
          <FormLayoutSubgroupTitle>Montage *</FormLayoutSubgroupTitle>
          <AdminRadioButton
            checked={
              editedLockingConsole.assemblyOnFrame === AssemblyOnFrame.ON_TOP
            }
            name="Aufgesetzt"
            onChange={() =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  assemblyOnFrame: AssemblyOnFrame.ON_TOP,
                }),
              )
            }
          />
          <AdminRadioButton
            checked={
              editedLockingConsole.assemblyOnFrame ===
              AssemblyOnFrame.INTEGRATED
            }
            name="Integriert"
            onChange={() =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  assemblyOnFrame: AssemblyOnFrame.INTEGRATED,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={4} columnWidth={1}>
          <InputField
            label="Montageplatz (mm) *"
            placeholder="z.B. 42"
            value={editedLockingConsole.assemblySpace || ''}
            additionalClass="consoles-data-dialog__assembly-space"
            onChange={(assemblySpace: string) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  assemblySpace: +assemblySpace,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={6} columnStart={1} columnWidth={1}>
          <Switch
            label="Aktiv"
            turnedOn={editedLockingConsole.active}
            onChange={(active: boolean) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  active,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={7} columnStart={1} columnWidth={1}>
          <Switch
            labelText="Vorschau"
            turnedOn={editedLockingConsole.preview}
            onChange={(preview: boolean) =>
              dispatch(
                updateEditedLockingConsole({
                  ...editedLockingConsole,
                  preview,
                }),
              )
            }
          />
        </FormLayoutElement>
      </FormLayout>
    </div>
  );
}

interface ConsoleAreaLockingConsolesDataViewProps {
  rangeOfApplication: RangeOfApplication;
}

export default function ConsoleAreaLockingConsolesDataView(
  props: ConsoleAreaLockingConsolesDataViewProps,
) {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const lockingConsoles = useSelector<
    AdminState,
    LockingConsoleWithOptionalId[]
  >(state =>
    state.adminFacade.lockingConsoles.filter(
      console => props.rangeOfApplication === console.rangeOfApplication,
    ),
  );

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    lockingConsoles,
    searchString,
    [],
    lockingConsole => [
      lockingConsole.systems.map(system => system.name).join(', '),
      lockingConsole.series.map(series => series.name).join(', '),
      lockingConsole.name,
      lockingConsole.artNr,
    ],
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function enableEditLockingConsole(
    lockingConsole: LockingConsoleWithOptionalId,
  ): void {
    setDialogIsShown(true);
    dispatch(updateEditedLockingConsole(lockingConsole));
  }

  function switchActiveAction(
    lockingConsole: LockingConsoleWithOptionalId,
  ): void {
    dispatch(
      changeLockingConsole({
        ...lockingConsole,
        active: !lockingConsole.active,
      }),
    );
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    dispatch(
      updateEditedLockingConsole({
        active: false,
        artNr: '',
        assemblyOnFrame: undefined,
        assemblySpace: 0,
        id: 0,
        name: '',
        preview: false,
        rangeOfApplication: props.rangeOfApplication,
        series: [],
        systems: [],
        withoutSystem: false,
        assemblyTypesInward: [],
        assemblyTypesOutward: [],
      }),
    );
  }

  function getCurrentTableContent(): LockingConsoleWithOptionalId[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <AdminLockingConsoleDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        rangeOfApplication={props.rangeOfApplication}
      />
      <div className="sub-header">
        <div className="sub-header__title"> Verriegelungskonsolen</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Verriegelungskonsolen suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>

      <div className="facade-console-set-assignment-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Systeme</th>
            <th>Serien</th>
            <th>Art. Nr.</th>
            <th>Name</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(lockingConsole => (
              <TableRow key={lockingConsole.id}>
                <td>
                  {lockingConsole.systems.map(system => system.name).join(', ')}
                </td>
                <td>
                  {lockingConsole.series.map(series => series.name).join(', ')}
                </td>
                <td>{lockingConsole?.artNr}</td>
                <td>{lockingConsole?.name}</td>
                <td>
                  <Switch
                    key={lockingConsole.id}
                    turnedOn={lockingConsole.active}
                    onChange={() => switchActiveAction(lockingConsole)}
                  />
                </td>
                <td>
                  <button
                    onClick={() => enableEditLockingConsole(lockingConsole)}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
}

interface AdminConsoleDialogProps {
  rangeOfApplication: RangeOfApplication;
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
}

function AdminLockingConsoleDialog(props: AdminConsoleDialogProps) {
  const editedLockingConsole = useSelector<
    AdminState,
    LockingConsoleWithOptionalId
  >(state => state.adminFacade.editedLockingConsole!);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  useEffect(() => {
    resetInputFields();
  }, [editedLockingConsole]);

  function resetInputFields(): void {
    // later
  }

  function mandatoryFieldsFilled(): boolean {
    if (!editedLockingConsole) {
      return false;
    }
    return fieldsFilled(
      conditionalValue(
        !editedLockingConsole.withoutSystem,
        editedLockingConsole.systems,
      ),
      conditionalValue(
        !editedLockingConsole.withoutSystem,
        editedLockingConsole.series,
      ),
      editedLockingConsole.artNr,
      editedLockingConsole.name,
      editedLockingConsole.assemblyOnFrame,
      editedLockingConsole.assemblySpace || undefined,
      [
        ...editedLockingConsole.assemblyTypesInward,
        ...editedLockingConsole.assemblyTypesOutward,
      ],
    );
  }

  function editLockingConsole(): void {
    dispatch(changeLockingConsole(editedLockingConsole));
  }

  function generateLockingConsole(): void {
    dispatch(createLockingConsole(editedLockingConsole));
  }

  function getEditedLockinConsoleId() {
    return editedLockingConsole !== undefined && editedLockingConsole.id;
  }

  return (
    <Dialog
      key={props.dialogIsShown.toString()}
      cancelAction={() => {
        dispatch(updateEditedLockingConsole(undefined));
      }}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      componentClass="full-view-dialog"
      headingText={
        getEditedLockinConsoleId()
          ? 'Verriegelungskonsolen bearbeiten'
          : 'Verriegelungskonsolen anlegen'
      }
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
          dispatch(updateEditedLockingConsole(undefined));
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              getEditedLockinConsoleId()
                ? editLockingConsole()
                : generateLockingConsole();
              props.setDialogIsShown(false);
            }
          : undefined,

        primaryActionLabelText: getEditedLockinConsoleId()
          ? 'Speichern'
          : 'Anlegen',
      }}
    >
      <AdminLockingConsoleDialogContent
        rangeOfApplication={props.rangeOfApplication}
      />
    </Dialog>
  );
}
