import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import './TestPhases.scss';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRadioButton from '../../elements/RadioButton';
import SearchField from '../../elements/SearchField';
import Pagination from '../components/Pagination';
import {
  BasicProfile,
  CVKurve,
  ExchangeProfile,
  FrameProfileFacade,
  SashProfileFacade,
  Series,
  System,
  TestPhase,
  TestPhaseApplicationOverviewWithOptionalId,
  TestPhaseApplicationWithOptionalId,
  TestPhaseMountingTypeWithOptionalId,
  TestPhaseProfileTypeWithOptionalId,
  TestPhaseSeriesOpeningDirectionWithOptionalId,
  TestPhaseSeriesWithOptionalId,
  TestPhaseSystemWithOptionalId,
} from '../../redux/admin/adminFacadeReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  ActiveFurtherInformationUiSection,
  Funktionssicherheit,
  MOUNTING_TYPES,
  POSSIBLE_OPENING_DIRECTION_OPTIONS,
  POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS,
  POSSIBLE_TEST_PHASE_MOUNTING_TYPES_OPTIONS,
  POSSIBLE_TEST_PHASE_PROFILE_TYPES_OPTIONS,
  PROFILE_TYPES,
  UiConstants,
  UmgebungsTemp,
  Waermebestaendigkeit,
} from '../constants';
import { fieldsFilled } from '../general/helpers';
import {
  changeTestPhase,
  copyTestPhaseFacade,
  createTestPhase,
  selectEditableTestPhase,
} from '../../redux/admin/adminFacadeActions';
import {
  addTestPhaseApplicationToEditedSeriesOpeningDirection,
  addTestPhaseMountingTypeToEditedTestPhaseApplication,
  addTestPhaseProfileTypeToEditedTestPhaseMountingType,
  addTestPhaseSeriesOpeningDirectionToEditableTestPhaseSeries,
  addTestPhaseSeriesToEditableTestPhaseSystem,
  addTestPhaseSystemToEditableTestPhase,
  initializeNewEditableTestPhase,
  removeTestPhaseApplicationFromEditedSeriesOpeningDirection,
  removeTestPhaseMountingTypeFromEditedTestPhaseApplication,
  removeTestPhaseProfileTypeFromEditedTestPhaseMountingType,
  removeTestPhaseSeriesFromEditableTestPhaseSystem,
  removeTestPhaseSeriesOpeningDirectionFromEditableTestPhaseSeries,
  removeTestPhaseSystemFromEditableTestPhase,
  updateEditedTestPhase,
  updateEditedTestPhaseApplication,
  updateEditedTestPhaseApplicationOverview,
  updateEditedTestPhaseApplicationOverviews,
  updateEditedTestPhaseLoads,
  updateEditedTestPhaseMountingType,
  updateEditedTestPhaseProfileType,
  updateEditedTestPhaseSeries,
  updateEditedTestPhaseSeriesOpeningDirection,
  updateEditedTestPhaseSystem,
} from '../../redux/admin/editedTestPhaseActions';
import TestPhaseSelectField from '../components/TestPhaseSelectField';
import {
  useEditedTestPhase,
  useEditedTestPhaseApplication,
  useEditedTestPhaseMountingType,
  useEditedTestPhaseOpeningDirection,
  useEditedTestPhaseSeries,
  useEditedTestPhaseSystem,
} from '../../hooks/selectorHooks';
import {
  MenuPlacement,
  SelectField,
  SelectOption,
} from '../../elements/SelectField';
import Button, { ButtonType } from '../../elements/Button';
import AdminListIcon from '../elements/AdminListIcon';
import CheckBox from '../../elements/CheckBox';
import WindOrSnowLoadsDialog from './WindOrSnowLoadsDialog';
import { AssignSelectGroup } from '../components/AssignSelectGroup';
import CopySeriesDialog from './CopySeriesDialog';
import Headline, { HeadlineSize } from '../../elements/Headline';
import { Application } from '../../redux/constants';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import { ACTIVE_STATE } from '../../redux/admin/adminRoofReducer';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';
import { Switch } from '../../elements/Switch';

const TestPhasesDataDialogTabNav: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__tab-nav test-phases__tab-nav">
      <AdminNavLink to="/admin/facade/test-phases/basic">
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to="/admin/facade/test-phases/basic" />}
          />
        </Routes>
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/test-phases/further-information">
        Weitere Angaben
      </AdminNavLink>
    </div>
  );
};

export function getTestPhaseFunctions<T>(
  selectedElementValueExtractor: (arg0: T) => string,
  options: T[],
): [
  (
    getState: () => AdminState,
    getOwnersChildList: (state: AdminState) => T[],
    addedElement: T,
  ) => boolean,
  (selectedElements: T[]) => T[],
] {
  function elementAlreadyIncludedInOwned(
    getState: () => AdminState,
    getOwnersChildList: (state: AdminState) => T[],
    addedElement: T,
  ): boolean {
    return getOwnersChildList(getState())
      .map(selectedElementValueExtractor)
      .includes(selectedElementValueExtractor(addedElement));
  }

  function deselectedElements(selectedElements: T[]): T[] {
    return options.filter(
      option =>
        !selectedElements
          ?.map(selectedElementValueExtractor)
          .includes(selectedElementValueExtractor(option)),
    );
  }

  return [elementAlreadyIncludedInOwned, deselectedElements];
}

export const [
  profileTypeAlreadyIncludedInMountingType,
  deselectedProfileTypes,
] = getTestPhaseFunctions(
  (profileType: TestPhaseProfileTypeWithOptionalId) => profileType.profileType,
  POSSIBLE_TEST_PHASE_PROFILE_TYPES_OPTIONS,
);

export const [
  mountingTypeAlreadyIncludedInApplication,
  deselectedMountingTypes,
] = getTestPhaseFunctions(
  (mountingType: TestPhaseMountingTypeWithOptionalId) =>
    mountingType.mountingType,
  POSSIBLE_TEST_PHASE_MOUNTING_TYPES_OPTIONS,
);

export const [
  openingDirectionAlreadyIncludedInSeries,
  deselectedOpeningDirections,
] = getTestPhaseFunctions(
  (openingDirection: TestPhaseSeriesOpeningDirectionWithOptionalId) =>
    openingDirection.oeffnungsrichtung,
  POSSIBLE_OPENING_DIRECTION_OPTIONS,
);

export const [applicationAlreadyIncludedInOpeningType, deselectedApplications] =
  getTestPhaseFunctions(
    (application: TestPhaseApplicationWithOptionalId) =>
      application.application,
    POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS,
  );

const TestPhasesDataDialogFurtherInformation: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedTestPhase = useEditedTestPhase();
  const editedTestPhaseSystem = useEditedTestPhaseSystem();
  const editedTestPhaseSeries = useEditedTestPhaseSeries();
  const editedTestPhaseOpeningDirection = useEditedTestPhaseOpeningDirection();
  const editedTestPhaseApplication = useEditedTestPhaseApplication();
  const editedTestPhaseMountingType = useEditedTestPhaseMountingType();

  const allSystems = useSelector<AdminState, System[]>(
    state => state.adminFacade.systems,
  );

  const allSeriesForSystem = useSelector<AdminState, Series[]>(
    state =>
      state.adminFacade.series.filter(
        series => series?.system.id === editedTestPhaseSystem?.system.id,
      ) || [],
  );

  const [activeSection, setActiveSection] =
    useState<ActiveFurtherInformationUiSection>(
      ActiveFurtherInformationUiSection.SYSTEM,
    );

  const [selectSystemKey, setSelectSystemKey] = useState<string>('');
  const [selectSeriesKey, setSelectSeriesKey] = useState<string>('');
  const [selectOpeningKey, setSelectOpeningKey] = useState<string>('');
  const [selectApplicationKey, setSelectApplicationKey] = useState<string>('');
  const [selectMountingTypeKey, setSelectMountingTypeKey] =
    useState<string>('');
  const [selectProfileTypeKey, setSelectProfileTypeKey] = useState<string>('');
  const [copySeriesDialogIsShown, setCopySeriesDialogIsShown] =
    useState<boolean>(false);

  useEffect(() => {
    if (activeSection !== ActiveFurtherInformationUiSection.SYSTEM) {
      setSelectSystemKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.SERIES) {
      setSelectSeriesKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.OPENING_DIRECTION) {
      setSelectOpeningKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.APPLICATION) {
      setSelectApplicationKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.MOUNTING_TYPE) {
      setSelectMountingTypeKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.PROFILE_TYPE) {
      setSelectProfileTypeKey(Date.now().toString());
    }
    if (activeSection === ActiveFurtherInformationUiSection.SYSTEM) {
      dispatch(updateEditedTestPhaseSeries(undefined));
      dispatch(updateEditedTestPhaseSeriesOpeningDirection(undefined));
      dispatch(updateEditedTestPhaseApplication(undefined));
      dispatch(updateEditedTestPhaseMountingType(undefined));
      dispatch(updateEditedTestPhaseProfileType(undefined));
    }
    if (activeSection === ActiveFurtherInformationUiSection.SERIES) {
      dispatch(updateEditedTestPhaseSeriesOpeningDirection(undefined));
      dispatch(updateEditedTestPhaseApplication(undefined));
      dispatch(updateEditedTestPhaseMountingType(undefined));
      dispatch(updateEditedTestPhaseProfileType(undefined));
    }
    if (activeSection === ActiveFurtherInformationUiSection.OPENING_DIRECTION) {
      dispatch(updateEditedTestPhaseApplication(undefined));
      dispatch(updateEditedTestPhaseMountingType(undefined));
      dispatch(updateEditedTestPhaseProfileType(undefined));
    }
    if (activeSection === ActiveFurtherInformationUiSection.APPLICATION) {
      dispatch(updateEditedTestPhaseMountingType(undefined));
      dispatch(updateEditedTestPhaseProfileType(undefined));
    }
    if (activeSection === ActiveFurtherInformationUiSection.MOUNTING_TYPE) {
      dispatch(updateEditedTestPhaseProfileType(undefined));
    }
  }, [activeSection]);

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <div className="facade-test-phases__data-dialog test-phases__data-dialog">
      <FormLayout additionalClass="test-phases__data-dialog-layout-further-information">
        <div className="test-phases__edit-phases-select-row test-phases__edit-phases-select-row-facade">
          <TestPhaseSelectField
            componentClassIdentifier="system"
            selectKey={selectSystemKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.SYSTEM
            }
            setActiveSection={setActiveSection}
            label="System"
            dialogHeadings={{
              dialog: 'Systeme zuordenen',
              leftTable: 'alle Systeme',
              rightTable: 'zugeordnete Systeme',
            }}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.SYSTEM)
            }
            displayNameExtractor={s => s.name}
            saveAction={(selected: System[], deselected: System[]) => {
              selected.forEach(selectedSystem =>
                dispatch(addTestPhaseSystemToEditableTestPhase(selectedSystem)),
              );
              deselected.forEach(deselectedSystem =>
                dispatch(
                  removeTestPhaseSystemFromEditableTestPhase(deselectedSystem),
                ),
              );
            }}
            elementSelected={(element: System) => {
              dispatch(
                updateEditedTestPhaseSystem(
                  editedTestPhase.testPhaseSystems.find(
                    testPhaseSystem => testPhaseSystem.system.id === element.id,
                  )!,
                ),
              );
            }}
            deselectedElements={allSystems.filter(
              system =>
                !editedTestPhase.testPhaseSystems
                  .map(testPhaseSystem => testPhaseSystem.system.id)
                  .includes(system.id),
            )}
            selectedElements={editedTestPhase.testPhaseSystems.map(
              testPhaseSystem => testPhaseSystem.system,
            )}
            highlightSelectedElement={true}
          />

          <TestPhaseSelectField
            componentClassIdentifier="serie"
            selectKey={selectSeriesKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.SERIES
            }
            setActiveSection={setActiveSection}
            label="Serie"
            dialogHeadings={{
              dialog: 'Serie zuordnen',
              leftTable: 'verfügbare Serien',
              rightTable: 'zugeordnete Serien',
            }}
            withCopyFunction={true}
            copyButtonPressed={() => setCopySeriesDialogIsShown(true)}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.SERIES)
            }
            deselectedElements={allSeriesForSystem.filter(
              series =>
                !editedTestPhaseSystem.testPhaseSeries
                  .map(testPhaseSeries => testPhaseSeries.series.id)
                  .includes(series.id),
            )}
            selectedElements={
              editedTestPhaseSystem?.testPhaseSeries?.map(
                testPhaseSeries => testPhaseSeries.series,
              ) || []
            }
            saveAction={(selected: Series[], deselected: Series[]) => {
              selected.forEach(selectedSeries =>
                dispatch(
                  addTestPhaseSeriesToEditableTestPhaseSystem(selectedSeries),
                ),
              );
              deselected.forEach(deselectedSeries =>
                dispatch(
                  removeTestPhaseSeriesFromEditableTestPhaseSystem(
                    deselectedSeries,
                  ),
                ),
              );
            }}
            displayNameExtractor={series => series.name}
            elementSelected={(element: Series) => {
              dispatch(
                updateEditedTestPhaseSeries(
                  editedTestPhaseSystem.testPhaseSeries.find(
                    testPhaseSeries => testPhaseSeries.series.id === element.id,
                  )!,
                ),
              );
            }}
            highlightSelectedElement={
              activeSection !== ActiveFurtherInformationUiSection.SYSTEM
            }
          />
          <CopySeriesDialog
            shown={copySeriesDialogIsShown}
            setShown={setCopySeriesDialogIsShown}
          />

          <TestPhaseSelectField
            componentClassIdentifier="opening-direction"
            selectKey={selectOpeningKey}
            isActive={
              activeSection ===
              ActiveFurtherInformationUiSection.OPENING_DIRECTION
            }
            setActiveSection={setActiveSection}
            label="Öffnungsrichtung"
            dialogHeadings={{
              dialog: 'Öffnungsrichtung zuordenen',
              leftTable: 'verfügbare Öffnungsrichtungen',
              rightTable: 'zugeordnete Öffnungsrichtung',
            }}
            withCopyFunction={false}
            onFocus={() =>
              setActiveSection(
                ActiveFurtherInformationUiSection.OPENING_DIRECTION,
              )
            }
            deselectedElements={deselectedOpeningDirections(
              editedTestPhaseSeries?.openingDirection,
            )}
            selectedElements={editedTestPhaseSeries?.openingDirection || []}
            saveAction={(
              selected: TestPhaseSeriesOpeningDirectionWithOptionalId[],
              deselected: TestPhaseSeriesOpeningDirectionWithOptionalId[],
            ) => {
              selected.forEach(selectedOpeningDirection =>
                dispatch(
                  addTestPhaseSeriesOpeningDirectionToEditableTestPhaseSeries(
                    selectedOpeningDirection,
                  ),
                ),
              );
              deselected.forEach(deselectedOpeningDirection =>
                dispatch(
                  removeTestPhaseSeriesOpeningDirectionFromEditableTestPhaseSeries(
                    deselectedOpeningDirection,
                  ),
                ),
              );
            }}
            displayNameExtractor={openingDirection =>
              openingDirection.oeffnungsrichtung
            }
            elementSelected={(
              element: TestPhaseSeriesOpeningDirectionWithOptionalId,
            ) => {
              dispatch(
                updateEditedTestPhaseSeriesOpeningDirection(
                  editedTestPhaseSeries.openingDirection.find(
                    openingDirection =>
                      openingDirection.oeffnungsrichtung ===
                      element.oeffnungsrichtung,
                  )!,
                ),
              );
            }}
            highlightSelectedElement={
              activeSection !== ActiveFurtherInformationUiSection.SERIES
            }
          />

          <TestPhaseSelectField
            componentClassIdentifier="application"
            selectKey={selectApplicationKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.APPLICATION
            }
            setActiveSection={setActiveSection}
            label="Anwendung"
            dialogHeadings={{
              dialog: 'Anwendung zuordenen',
              leftTable: 'verfügbare Anwendungen',
              rightTable: 'zugeordnete Anwendungen',
            }}
            withCopyFunction={false}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.APPLICATION)
            }
            deselectedElements={deselectedApplications(
              editedTestPhaseOpeningDirection?.anwendungen,
            )}
            selectedElements={
              editedTestPhaseOpeningDirection?.anwendungen || []
            }
            saveAction={(
              selected: TestPhaseApplicationWithOptionalId[],
              deselected: TestPhaseApplicationWithOptionalId[],
            ) => {
              selected.forEach(selectedApplication =>
                dispatch(
                  addTestPhaseApplicationToEditedSeriesOpeningDirection(
                    selectedApplication,
                  ),
                ),
              );
              deselected.forEach(deselectedApplication =>
                dispatch(
                  removeTestPhaseApplicationFromEditedSeriesOpeningDirection(
                    deselectedApplication,
                  ),
                ),
              );
            }}
            displayNameExtractor={application => application.application}
            elementSelected={(element: TestPhaseApplicationWithOptionalId) =>
              dispatch(
                updateEditedTestPhaseApplication(
                  editedTestPhaseOpeningDirection.anwendungen.find(
                    application =>
                      application.application === element.application,
                  )!,
                ),
              )
            }
            highlightSelectedElement={
              activeSection !==
              ActiveFurtherInformationUiSection.OPENING_DIRECTION
            }
          />

          <TestPhaseSelectField
            componentClassIdentifier="installation-type"
            selectKey={selectMountingTypeKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.MOUNTING_TYPE
            }
            setActiveSection={setActiveSection}
            label="Einbauart"
            dialogHeadings={{
              dialog: 'Einbauart zuordenen',
              leftTable: 'verfügbare Einbauarten',
              rightTable: 'zugeordnete Einbauarten',
            }}
            withCopyFunction={false}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.MOUNTING_TYPE)
            }
            deselectedElements={deselectedMountingTypes(
              editedTestPhaseApplication?.mountingTypes,
            )}
            selectedElements={editedTestPhaseApplication?.mountingTypes || []}
            saveAction={(
              selected: TestPhaseMountingTypeWithOptionalId[],
              deselected: TestPhaseMountingTypeWithOptionalId[],
            ) => {
              selected.forEach(selectedMountingType =>
                dispatch(
                  addTestPhaseMountingTypeToEditedTestPhaseApplication(
                    selectedMountingType,
                  ),
                ),
              );
              deselected.forEach(deselectedMountingType =>
                dispatch(
                  removeTestPhaseMountingTypeFromEditedTestPhaseApplication(
                    deselectedMountingType,
                  ),
                ),
              );
            }}
            displayNameExtractor={mountingType =>
              MOUNTING_TYPES.find(
                label => label.value === mountingType.mountingType,
              )?.label || ''
            }
            elementSelected={(element: TestPhaseMountingTypeWithOptionalId) =>
              dispatch(
                updateEditedTestPhaseMountingType(
                  editedTestPhaseApplication.mountingTypes.find(
                    mountingType =>
                      mountingType.mountingType === element.mountingType,
                  )!,
                ),
              )
            }
            highlightSelectedElement={
              activeSection !== ActiveFurtherInformationUiSection.APPLICATION
            }
          />

          <TestPhaseSelectField
            componentClassIdentifier="profile-type"
            selectKey={selectProfileTypeKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.PROFILE_TYPE
            }
            setActiveSection={setActiveSection}
            label="Profiltyp"
            dialogHeadings={{
              dialog: 'Profiltyp zuordenen',
              leftTable: 'verfügbare Profiltypen',
              rightTable: 'zugeordnete Profiltypen',
            }}
            withCopyFunction={false}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.PROFILE_TYPE)
            }
            deselectedElements={deselectedProfileTypes(
              editedTestPhaseMountingType?.profileTypes,
            )}
            selectedElements={editedTestPhaseMountingType?.profileTypes || []}
            saveAction={(
              selected: TestPhaseProfileTypeWithOptionalId[],
              deselected: TestPhaseProfileTypeWithOptionalId[],
            ) => {
              selected.forEach(selectedProfileType =>
                dispatch(
                  addTestPhaseProfileTypeToEditedTestPhaseMountingType(
                    selectedProfileType,
                  ),
                ),
              );
              deselected.forEach(deselectedProfileType =>
                dispatch(
                  removeTestPhaseProfileTypeFromEditedTestPhaseMountingType(
                    deselectedProfileType,
                  ),
                ),
              );
            }}
            displayNameExtractor={profileType =>
              PROFILE_TYPES.find(
                profileTypeSelectOption =>
                  profileTypeSelectOption.value === profileType.profileType,
              )?.label || ''
            }
            elementSelected={(element: TestPhaseProfileTypeWithOptionalId) => {
              dispatch(
                updateEditedTestPhaseProfileType(
                  editedTestPhaseMountingType.profileTypes.find(
                    profileType =>
                      profileType.profileType === element.profileType!,
                  ),
                ),
              );
            }}
            highlightSelectedElement={
              activeSection !== ActiveFurtherInformationUiSection.MOUNTING_TYPE
            }
          />
        </div>
        <EditTestPhasesRow
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </FormLayout>
    </div>
  );
};

const TestPhasesDataDialogBasic: FC<React.PropsWithChildren<unknown>> = () => {
  const editedTestPhase = useEditedTestPhase();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <FormLayout additionalClass="test-phases__data-dialog-layout-basic">
      <InputField
        label="Name"
        placeholder="Testphase 0815"
        value={editedTestPhase.name}
        additionalClass="test-phases__name"
        onChange={name =>
          dispatch(updateEditedTestPhase({ ...editedTestPhase, name: name }))
        }
      />
      <InputFieldNumber
        label="min. Abstand vom Drehband bei seitl. Montage (%) bei Kipp- oder Klappanwendung*"
        placeholder="z.B. 42"
        value={editedTestPhase.minAbstandDrehband}
        additionalClass="test-phases__distance-to-hindge"
        onChange={minAbstandDrehband =>
          dispatch(
            updateEditedTestPhase({
              ...editedTestPhase,
              minAbstandDrehband: minAbstandDrehband,
            }),
          )
        }
      />

      <div className="test-phases__switch">
        <AdminRadioButton
          checked={editedTestPhase.active === ACTIVE_STATE.INACTIVE}
          name="Deaktiviert"
          onChange={() =>
            dispatch(
              updateEditedTestPhase({
                ...editedTestPhase,
                active: ACTIVE_STATE.INACTIVE,
              }),
            )
          }
        />
        <AdminRadioButton
          checked={editedTestPhase.active === ACTIVE_STATE.PREVIEW}
          name="Vorschau"
          onChange={() =>
            dispatch(
              updateEditedTestPhase({
                ...editedTestPhase,
                active: ACTIVE_STATE.PREVIEW,
              }),
            )
          }
        />
        <AdminRadioButton
          checked={editedTestPhase.active === ACTIVE_STATE.ACTIVE}
          name="Aktiv"
          onChange={() =>
            dispatch(
              updateEditedTestPhase({
                ...editedTestPhase,
                active: ACTIVE_STATE.ACTIVE,
              }),
            )
          }
        />
      </div>
    </FormLayout>
  );
};

const TestPhasesDataDialogFurtherInformationSystems: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedTestPhaseSystem = useSelector<
    AdminState,
    TestPhaseSystemWithOptionalId
  >(state => state.adminFacade.editedTestPhaseSystem!);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  if (!editedTestPhaseSystem) {
    return null;
  }

  function setKlassFunk(value: Funktionssicherheit): void {
    dispatch(
      updateEditedTestPhaseSystem(editedTestPhaseSystem, { klassFunk: value }),
    );
  }

  function setTemperature(value: UmgebungsTemp): void {
    dispatch(
      updateEditedTestPhaseSystem(editedTestPhaseSystem, {
        umgebungstemp: value,
      }),
    );
  }

  function setHeat(value: Waermebestaendigkeit): void {
    dispatch(
      updateEditedTestPhaseSystem(editedTestPhaseSystem, {
        klassWaermebest: value,
      }),
    );
  }

  function setMaxWindLoad(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSystem(editedTestPhaseSystem, {
        maxWindLoad: value,
      }),
    );
  }

  function setMinGlassThickness(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSystem(editedTestPhaseSystem, {
        minGlassThickness: value,
      }),
    );
  }

  return (
    <FormLayout additionalClass="test-phases__edit-phases-row-system">
      <div className="test-phases__function-security">
        <FormLayoutSubgroupTitle>
          Klassifizierung der Funktionssicherheit
        </FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={
            Funktionssicherheit.RE1000 === editedTestPhaseSystem.klassFunk
          }
          name="Re 1000"
          onChange={() => setKlassFunk(Funktionssicherheit.RE1000)}
        />
        <AdminRadioButton
          checked={
            Funktionssicherheit.RE1000PLUS === editedTestPhaseSystem.klassFunk
          }
          name="Re 1000 + Le 10.000"
          onChange={() => setKlassFunk(Funktionssicherheit.RE1000PLUS)}
        />
      </div>

      <div className="test-phases__temperature">
        <FormLayoutSubgroupTitle>Umgebungstemperatur</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={UmgebungsTemp.ZERO === editedTestPhaseSystem.umgebungstemp}
          name="0°"
          onChange={() => setTemperature(UmgebungsTemp.ZERO)}
        />
        <AdminRadioButton
          checked={
            UmgebungsTemp.FIVE_UNDER_ZERO ===
            editedTestPhaseSystem.umgebungstemp
          }
          name="-5°"
          onChange={() => setTemperature(UmgebungsTemp.FIVE_UNDER_ZERO)}
        />
        <AdminRadioButton
          checked={
            UmgebungsTemp.TEN_UNDER_ZERO === editedTestPhaseSystem.umgebungstemp
          }
          name="-10°"
          onChange={() => setTemperature(UmgebungsTemp.TEN_UNDER_ZERO)}
        />
        <AdminRadioButton
          checked={
            UmgebungsTemp.FIFTEEN_UNDER_ZERO ===
            editedTestPhaseSystem.umgebungstemp
          }
          name="-15°"
          onChange={() => setTemperature(UmgebungsTemp.FIFTEEN_UNDER_ZERO)}
        />
      </div>

      <div className="test-phases__heat">
        <FormLayoutSubgroupTitle>Wärmebeständigkeit</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.B300E === editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.B300E}
          onChange={() => setHeat(Waermebestaendigkeit.B300E)}
        />
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.B300F === editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.B300F}
          onChange={() => setHeat(Waermebestaendigkeit.B300F)}
        />
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.N_P_D === editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.N_P_D}
          onChange={() => setHeat(Waermebestaendigkeit.N_P_D)}
        />
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.B300_N_P_D ===
            editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.B300_N_P_D}
          onChange={() => setHeat(Waermebestaendigkeit.B300_N_P_D)}
        />
      </div>
      <div className="test-phases__loads">
        <FormLayoutSubgroupTitle>Max. Windlast</FormLayoutSubgroupTitle>
        <InputFieldNumber
          value={editedTestPhaseSystem.maxWindLoad}
          onChange={setMaxWindLoad}
        />
      </div>
      <div className="test-phases__minGlassThickness">
        <FormLayoutSubgroupTitle>min. Glasstärke (mm)*</FormLayoutSubgroupTitle>
        <InputFieldNumber
          value={editedTestPhaseSystem.minGlassThickness}
          onChange={setMinGlassThickness}
        />
      </div>
    </FormLayout>
  );
};

const TestPhasesDataDialogFurtherInformationSeries: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedTestPhaseSeries = useSelector<
    AdminState,
    TestPhaseSeriesWithOptionalId
  >(state => state.adminFacade.editedTestPhaseSeries!);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [
    applicationOverviewsDialogIsShown,
    setApplicationOverviewsDialogIsShown,
  ] = useState(false);
  const [windLoadsDialogIsShown, setWindLoadsDialogIsShown] = useState(false);
  const frameProfilesFacadeForSeries = useSelector<
    AdminState,
    FrameProfileFacade[]
  >(state =>
    state.adminFacade.frameProfiles.filter(
      f => f.seriesId === editedTestPhaseSeries?.series.id && f.active,
    ),
  );
  const sashProfilesFacadeForSeries = useSelector<
    AdminState,
    SashProfileFacade[]
  >(state =>
    state.adminFacade.sashProfiles.filter(
      f => f.seriesId === editedTestPhaseSeries?.series.id && f.active,
    ),
  );
  const exchangeProfilesForSeries = useSelector<AdminState, ExchangeProfile[]>(
    state =>
      state.adminFacade.exchangeProfiles.filter(
        f => f.seriesId === editedTestPhaseSeries?.series.id && f.active,
      ),
  );
  const baseProfilesForSeries = useSelector<AdminState, BasicProfile[]>(state =>
    state.adminFacade.basicProfiles.filter(
      f => f.seriesId === editedTestPhaseSeries?.series.id && f.active,
    ),
  );

  function editApplicationOverviews(): void {
    dispatch(
      updateEditedTestPhaseApplicationOverviews(
        editedTestPhaseSeries.applicationOverviews,
      ),
    );
    setApplicationOverviewsDialogIsShown(true);
  }

  function editWindLoads(): void {
    dispatch(updateEditedTestPhaseLoads(editedTestPhaseSeries.windLoads));
    setWindLoadsDialogIsShown(true);
  }

  if (!editedTestPhaseSeries) {
    return null;
  }

  function setMinGepruefteFlaeche(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSeries(editedTestPhaseSeries, {
        minGepruefteFlaeche: value,
      }),
    );
  }

  function setMaxGepruefteFlaeche(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSeries(editedTestPhaseSeries, {
        maxGepruefteFlaeche: value,
      }),
    );
  }

  function setMinEinbauwinkel(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSeries(editedTestPhaseSeries, {
        minEinbauwinkel: value,
      }),
    );
  }

  function setMaxEinbauwinkel(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSeries(editedTestPhaseSeries, {
        maxEinbauwinkel: value,
      }),
    );
  }

  function setInCertificate(value: boolean): void {
    dispatch(
      updateEditedTestPhaseSeries(editedTestPhaseSeries, {
        inCertificate: value,
      }),
    );
  }

  return (
    <FormLayout additionalClass="test-phases__edit-phases-row-series">
      <div className="test-phases__edit-phases-row-series-inputs">
        <div className="test-phases__tested-area test-phases__double-field-group">
          <div className="test-phases__title">Geprüfte Fläche (mA²) *</div>
          <InputFieldNumber
            label="Min."
            placeholder="z.B 42"
            value={editedTestPhaseSeries.minGepruefteFlaeche}
            additionalClass="test-phases__input-1"
            onChange={setMinGepruefteFlaeche}
          />
          <InputFieldNumber
            label="Max."
            placeholder="z.B 42"
            value={editedTestPhaseSeries.maxGepruefteFlaeche}
            additionalClass="test-phases__input-2"
            onChange={setMaxGepruefteFlaeche}
          />
        </div>
        <div className="test-phases__angle test-phases__double-field-group">
          <div className="test-phases__title">Einbauwinkel (°) *</div>
          <InputFieldNumber
            label="Min."
            placeholder="z.B 42"
            value={editedTestPhaseSeries.minEinbauwinkel}
            additionalClass="test-phases__input-1"
            onChange={setMinEinbauwinkel}
          />
          <InputFieldNumber
            label="Max."
            placeholder="z.B 42"
            value={editedTestPhaseSeries.maxEinbauwinkel}
            additionalClass="test-phases__input-2"
            onChange={setMaxEinbauwinkel}
          />
        </div>
        <div className="test-phases__in-certificate">
          <Switch
            labelText="im Zertifikat vorhanden"
            turnedOn={editedTestPhaseSeries.inCertificate}
            onChange={setInCertificate}
          />
        </div>
        <Button
          labelText="Anwendungsübersichten"
          action={editApplicationOverviews}
          type={ButtonType.FRAME}
          additionalClass="test-phases__button"
        />
        <Button
          labelText="Windlast (Pa)"
          action={editWindLoads}
          type={ButtonType.FRAME}
          additionalClass="test-phases__button"
        />
        {applicationOverviewsDialogIsShown && (
          <ApplicationOverviewsDialog
            shown={applicationOverviewsDialogIsShown}
            setShown={setApplicationOverviewsDialogIsShown}
            editedTestPhaseSeries={editedTestPhaseSeries}
            updateEditedSeries={(editedSeries, values) =>
              dispatch(
                updateEditedTestPhaseSeries(editedTestPhaseSeries, values),
              )
            }
          />
        )}
        {windLoadsDialogIsShown && (
          <WindOrSnowLoadsDialog
            shown={windLoadsDialogIsShown}
            setShown={setWindLoadsDialogIsShown}
            editedTestPhaseSeries={editedTestPhaseSeries}
            updateEditedSeries={(editedSeries, values) =>
              dispatch(
                updateEditedTestPhaseSeries(editedTestPhaseSeries, values),
              )
            }
            snowLoad={false}
          />
        )}
      </div>
      <div className="test-phases__edit-phases-row-series-profiles">
        <AssignSelectGroup
          multipleSelectAllowed={true}
          headings={{
            componentHeadingWithoutDialog: 'Blendrahmen zuordnen',
            headingLeftTable: 'nicht ausgewählt',
            headingRightTable: 'ausgewählt',
          }}
          componentClass=""
          addElements={(frameProfilesFacade: FrameProfileFacade[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                frameProfiles: [
                  ...editedTestPhaseSeries.frameProfiles,
                  ...frameProfilesFacade,
                ],
              }),
            )
          }
          removeElements={(frameProfileFacades: FrameProfileFacade[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                frameProfiles: editedTestPhaseSeries.frameProfiles.filter(
                  f => !frameProfileFacades.includes(f),
                ),
              }),
            )
          }
          selectedElements={editedTestPhaseSeries.frameProfiles}
          deselectedElements={frameProfilesFacadeForSeries.filter(
            f =>
              !editedTestPhaseSeries.frameProfiles
                .map(f2 => f2.id)
                .includes(f.id),
          )}
          displayNameExtractor={(f: FrameProfileFacade) => f.artNr}
          backgroundWhite={true}
        />

        <AssignSelectGroup
          multipleSelectAllowed={true}
          headings={{
            componentHeadingWithoutDialog: 'Wechselprofil zuordnen',
            headingLeftTable: 'nicht ausgewählt',
            headingRightTable: 'ausgewählt',
          }}
          componentClass=""
          addElements={(exchangeProfiles: ExchangeProfile[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                exchangeProfiles: [
                  ...editedTestPhaseSeries.exchangeProfiles,
                  ...exchangeProfiles,
                ],
              }),
            )
          }
          removeElements={(exchangeProfiles: ExchangeProfile[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                exchangeProfiles: editedTestPhaseSeries.exchangeProfiles.filter(
                  exchangeProfile =>
                    !exchangeProfiles.includes(exchangeProfile),
                ),
              }),
            )
          }
          selectedElements={editedTestPhaseSeries.exchangeProfiles}
          deselectedElements={exchangeProfilesForSeries.filter(
            f =>
              !editedTestPhaseSeries.exchangeProfiles
                .map(f2 => f2.id)
                .includes(f.id),
          )}
          displayNameExtractor={(f: ExchangeProfile) => f.artNr}
          backgroundWhite={true}
        />

        <AssignSelectGroup
          multipleSelectAllowed={true}
          headings={{
            componentHeadingWithoutDialog: 'Flügelprofil zuordnen',
            headingLeftTable: 'nicht ausgewählt',
            headingRightTable: 'ausgewählt',
          }}
          componentClass=""
          addElements={(sashProfilesFacade: SashProfileFacade[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                sashProfiles: [
                  ...editedTestPhaseSeries.sashProfiles,
                  ...sashProfilesFacade,
                ],
              }),
            )
          }
          removeElements={(sashProfilesFacade: SashProfileFacade[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                sashProfiles: editedTestPhaseSeries.sashProfiles.filter(
                  sashProfile => !sashProfilesFacade.includes(sashProfile),
                ),
              }),
            )
          }
          selectedElements={editedTestPhaseSeries.sashProfiles}
          deselectedElements={sashProfilesFacadeForSeries.filter(
            f =>
              !editedTestPhaseSeries.sashProfiles
                .map(f2 => f2.id)
                .includes(f.id),
          )}
          displayNameExtractor={(f: SashProfileFacade) => f.artNr}
          backgroundWhite={true}
        />

        <AssignSelectGroup
          multipleSelectAllowed={true}
          headings={{
            componentHeadingWithoutDialog: 'Grundprofil zuordnen',
            headingLeftTable: 'nicht ausgewählt',
            headingRightTable: 'ausgewählt',
          }}
          componentClass=""
          addElements={(basicProfiles: BasicProfile[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                baseProfiles: [
                  ...editedTestPhaseSeries.baseProfiles,
                  ...basicProfiles,
                ],
              }),
            )
          }
          removeElements={(basicProfiles: BasicProfile[]) =>
            dispatch(
              updateEditedTestPhaseSeries(editedTestPhaseSeries, {
                baseProfiles: editedTestPhaseSeries.baseProfiles.filter(
                  f2 => !basicProfiles.includes(f2),
                ),
              }),
            )
          }
          selectedElements={editedTestPhaseSeries.baseProfiles}
          deselectedElements={baseProfilesForSeries.filter(
            f =>
              !editedTestPhaseSeries.baseProfiles
                .map(f2 => f2.id)
                .includes(f.id),
          )}
          displayNameExtractor={(f: BasicProfile) => f.artNr}
          backgroundWhite={true}
        />
      </div>
    </FormLayout>
  );
};

interface ApplicationOverviewsDialogProps<T> {
  shown: boolean;
  setShown: (b: boolean) => void;
  editedTestPhaseSeries: T | undefined;
  updateEditedSeries: (
    t: T,
    values: {
      applicationOverviews: TestPhaseApplicationOverviewWithOptionalId[];
    },
  ) => void;
  // editedApplicationOverviews: TestPhaseApplicationOverviewWithOptionalId[];
}

export const ApplicationOverviewsDialog = <T,>(
  props: ApplicationOverviewsDialogProps<T>,
): ReactElement | null => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const [editedApplicationOverviewIndex, setEditedApplicationOverviewIndex] =
    useState(0);
  const applicationOverviews = useSelector<
    AdminState,
    TestPhaseApplicationOverviewWithOptionalId[]
  >(state => state.adminFacade.editedTestPhaseApplicationOverviews);

  function editOverview(
    applicationOverview: TestPhaseApplicationOverviewWithOptionalId,
  ): void {
    dispatch(updateEditedTestPhaseApplicationOverview(applicationOverview));
    setEditedApplicationOverviewIndex(
      applicationOverviews.indexOf(applicationOverview),
    );
    setDialogIsShown(true);
  }

  function deleteOverview(
    applicationOverview: TestPhaseApplicationOverviewWithOptionalId,
  ): void {
    dispatch(
      updateEditedTestPhaseApplicationOverviews(
        applicationOverviews.filter(ao => ao !== applicationOverview),
      ),
    );
  }

  function newOverview(): void {
    dispatch(updateEditedTestPhaseApplicationOverview({}));
    setEditedApplicationOverviewIndex(-1);
    setDialogIsShown(true);
  }

  if (!props.editedTestPhaseSeries) {
    return null;
  }

  return (
    <Dialog
      headingText="Anwendungsübersichten"
      setDialogIsShown={props.setShown}
      dialogIsShown={props.shown}
      componentClass="test-phases__data-dialog-application-overview"
      nested={true}
      footerProps={{
        notTranslated: true,
        cancelAction: () => props.setShown(false),
        saveAction: () => {
          props.setShown(false);
          props.updateEditedSeries(props.editedTestPhaseSeries!, {
            applicationOverviews: applicationOverviews,
          });
        },
        primaryActionLabelText: 'Speichern',
      }}
    >
      <Button
        labelText="Neue Anwendungsübersicht"
        action={newOverview}
        type={ButtonType.FRAME}
        additionalClass="test-phases__data-dialog-application-overview-button"
      />
      <Table>
        <TableHeader>
          <th>min. Breite (mm)</th>
          <th>max. Breite (mm)</th>
          <th>min. Höhe (mm)</th>
          <th>max. Höhe (mm)</th>
          <th>Anz. Antriebe</th>
          <th>Einw.</th>
          <th>Ausw.</th>
          <th>seitl. Mont.</th>
          <th>gegüb. Band</th>
          <th>Traverse</th>
          <th>Kipp</th>
          <th>Klapp</th>
          <th>Dreh</th>
          <th>Senkklapp</th>
          <th className="table-header__action-column"></th>
          <th className="table-header__action-column"></th>
        </TableHeader>
        {applicationOverviews.map(applicationOverview => (
          <>
            <TableRow>
              <td>{applicationOverview.minBreite}</td>
              <td>{applicationOverview.maxBreite}</td>
              <td>{applicationOverview.minHoehe}</td>
              <td>{applicationOverview.maxHoehe}</td>
              <td>
                {applicationOverview.minAntriebe} bis{' '}
                {applicationOverview.maxAntriebe}
              </td>
              <td>
                <AdminListIcon
                  checked={applicationOverview.oeffnungsrichtung_einw}
                />
              </td>
              <td>
                <AdminListIcon
                  checked={applicationOverview.oeffnungsrichtung_ausw}
                />
              </td>
              <td>
                <AdminListIcon checked={applicationOverview.seitlMontage} />
              </td>
              <td>
                <AdminListIcon checked={applicationOverview.gegenueberBand} />
              </td>
              <td>
                <AdminListIcon checked={applicationOverview.traverse} />
              </td>
              <td>
                <AdminListIcon checked={applicationOverview.kipp} />
              </td>
              <td>
                <AdminListIcon checked={applicationOverview.klapp} />
              </td>
              <td>
                <AdminListIcon checked={applicationOverview.dreh} />
              </td>
              <td>
                <AdminListIcon checked={applicationOverview.senkklapp} />
              </td>
              <td>
                <Button
                  action={() => editOverview(applicationOverview)}
                  label={UiConstants.EDIT}
                  type={ButtonType.SECONDARY}
                />
              </td>
              <td>
                <Button
                  action={() => deleteOverview(applicationOverview)}
                  label={UiConstants.DELETE}
                  type={ButtonType.SECONDARY}
                />
              </td>
            </TableRow>
          </>
        ))}
        {dialogIsShown && (
          <ApplicationOverviewsEditDialog
            shown={dialogIsShown}
            setShown={setDialogIsShown}
            editedApplicationOverviewIndex={editedApplicationOverviewIndex}
          />
        )}
      </Table>
    </Dialog>
  );
};

interface ApplicationOverviewsEditDialogProps {
  shown: boolean;
  setShown: (b: boolean) => void;
  editedApplicationOverviewIndex: number;
}

const ApplicationOverviewsEditDialog: FC<
  React.PropsWithChildren<ApplicationOverviewsEditDialogProps>
> = props => {
  const editedApplicationOverview = useSelector<
    AdminState,
    TestPhaseApplicationOverviewWithOptionalId | undefined
  >(
    state =>
      state.adminFacade.editedTestPhaseApplicationOverview as
        | TestPhaseApplicationOverviewWithOptionalId
        | undefined,
  );
  const applicationOverviews = useSelector<
    AdminState,
    TestPhaseApplicationOverviewWithOptionalId[]
  >(state => state.adminFacade.editedTestPhaseApplicationOverviews);

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  if (!editedApplicationOverview) {
    return null;
  }

  function mandatoryFieldsFilled(): boolean {
    return fieldsFilled(
      editedApplicationOverview?.minBreite,
      editedApplicationOverview?.maxBreite,
      editedApplicationOverview?.minHoehe,
      editedApplicationOverview?.maxHoehe,
      editedApplicationOverview?.minAntriebe,
      editedApplicationOverview?.maxAntriebe,
    );
  }

  function saveAction(): (() => void) | undefined {
    if (mandatoryFieldsFilled()) {
      return () => {
        props.setShown(false);
        const updatedApplicationOverviews = [...applicationOverviews];
        if (props.editedApplicationOverviewIndex >= 0) {
          updatedApplicationOverviews.splice(
            props.editedApplicationOverviewIndex,
            1,
            editedApplicationOverview!,
          );
        } else {
          updatedApplicationOverviews.unshift(editedApplicationOverview!);
        }
        dispatch(
          updateEditedTestPhaseApplicationOverviews(
            updatedApplicationOverviews,
          ),
        );
      };
    } else {
      return undefined;
    }
  }

  return (
    <Dialog
      isConfirmation={true}
      setDialogIsShown={props.setShown}
      dialogIsShown={props.shown}
      componentClass=""
      headingText="Anwendungsübersicht hinzufügen / bearbeiten"
      footerProps={{
        notTranslated: true,
        cancelAction: () => props.setShown(false),
        saveAction: saveAction(),
        primaryActionLabelText: 'Speichern',
      }}
      nested={true}
    >
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={2}>
          <InputFieldNumber
            label="min. Breite"
            placeholder="z.B. 42"
            value={editedApplicationOverview.minBreite}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseApplicationOverview({
                  ...editedApplicationOverview,
                  minBreite: v,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={1} columnStart={3} columnWidth={2}>
          <InputFieldNumber
            label="max. Breite"
            placeholder="z.B. 42"
            value={editedApplicationOverview.maxBreite}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseApplicationOverview({
                  ...editedApplicationOverview,
                  maxBreite: v,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={2}>
          <InputFieldNumber
            label="min. Höhe"
            placeholder="z.B. 42"
            value={editedApplicationOverview.minHoehe}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseApplicationOverview({
                  ...editedApplicationOverview,
                  minHoehe: v,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={3} columnWidth={2}>
          <InputFieldNumber
            label="max. Höhe"
            placeholder="z.B. 42"
            value={editedApplicationOverview.maxHoehe}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseApplicationOverview({
                  ...editedApplicationOverview,
                  maxHoehe: v,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1} columnWidth={2}>
          <InputFieldNumber
            label="min. Anzahl Antriebe"
            placeholder="z.B. 42"
            value={editedApplicationOverview.minAntriebe}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseApplicationOverview({
                  ...editedApplicationOverview,
                  minAntriebe: v,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={3} columnWidth={2}>
          <InputFieldNumber
            label="max. Anzahl Antriebe"
            placeholder="z.B. 42"
            value={editedApplicationOverview.maxAntriebe}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseApplicationOverview({
                  ...editedApplicationOverview,
                  maxAntriebe: v,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={6}>
          <Headline
            additionalClass="test-phases__data-dialog-windload-form-headlinePosition"
            size={HeadlineSize.MD}
          >
            Öffnungsrichtung
          </Headline>
        </FormLayoutElement>
        <FormLayoutElement rowStart={5} columnStart={1} columnWidth={6}>
          <EditApplicationOverviewCheckbox
            label="Einwärts"
            propertyName="oeffnungsrichtung_einw"
          />
          <EditApplicationOverviewCheckbox
            label="Auswärts"
            propertyName="oeffnungsrichtung_ausw"
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={6} columnStart={1} columnWidth={6}>
          <Headline
            additionalClass="test-phases__data-dialog-windload-form-headlinePosition"
            size={HeadlineSize.MD}
          >
            Montageposition
          </Headline>
        </FormLayoutElement>
        <FormLayoutElement rowStart={7} columnStart={1} columnWidth={6}>
          <EditApplicationOverviewCheckbox
            label="seitl. Montage"
            propertyName="seitlMontage"
          />
          <EditApplicationOverviewCheckbox
            label="gegüb. Band"
            propertyName="gegenueberBand"
          />
          <EditApplicationOverviewCheckbox
            label="Traverse"
            propertyName="traverse"
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={8} columnStart={1} columnWidth={6}>
          <Headline
            additionalClass="test-phases__data-dialog-windload-form-headlinePosition"
            size={HeadlineSize.MD}
          >
            Öffnungsart
          </Headline>
        </FormLayoutElement>
        <FormLayoutElement rowStart={9} columnStart={1} columnWidth={6}>
          <EditApplicationOverviewCheckbox label="Kipp" propertyName="kipp" />
          <EditApplicationOverviewCheckbox label="Klapp" propertyName="klapp" />
          <EditApplicationOverviewCheckbox label="Dreh" propertyName="dreh" />
          <EditApplicationOverviewCheckbox
            label="Senkklapp"
            propertyName="senkklapp"
          />
        </FormLayoutElement>
      </FormLayout>
    </Dialog>
  );
};

export type SubType<Base, Condition> = Pick<
  Base,
  {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
  }[keyof Base]
>;

interface EditApplicationOverviewCheckboxProps {
  label: string;
  propertyName: keyof SubType<
    TestPhaseApplicationOverviewWithOptionalId,
    boolean
  >;
}

const EditApplicationOverviewCheckbox: FC<
  React.PropsWithChildren<EditApplicationOverviewCheckboxProps>
> = props => {
  const editedApplicationOverview = useSelector<
    AdminState,
    TestPhaseApplicationOverviewWithOptionalId
  >(
    state =>
      state.adminFacade
        .editedTestPhaseApplicationOverview! as TestPhaseApplicationOverviewWithOptionalId,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <CheckBox
      label={props.label}
      checked={editedApplicationOverview[props.propertyName || 'kipp']}
      onClick={() =>
        dispatch(
          updateEditedTestPhaseApplicationOverview({
            ...editedApplicationOverview,
            [props.propertyName || 'kipp']:
              !editedApplicationOverview[props.propertyName || 'kipp'],
          }),
        )
      }
    />
  );
};

const TestPhasesDataDialogFurtherInformationProfileType: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedTestPhaseProfileType = useSelector<
    AdminState,
    TestPhaseProfileTypeWithOptionalId
  >(state => state.adminFacade.editedTestPhaseProfileType!);
  const allCvCurves = useSelector<AdminState, CVKurve[]>(
    state => state.adminFacade.cvKurven,
  );

  if (!editedTestPhaseProfileType) {
    return null;
  }

  function cvCurvesToSelect(): SelectOption<string>[] {
    const options: SelectOption<string>[] = [];
    allCvCurves.forEach(curve => {
      options.push({ value: curve.id.toString(), label: curve.name });
    });
    return options;
  }

  function findCvCurveById(id: number): CVKurve | undefined {
    return allCvCurves.find(cvCurve => cvCurve.id === id);
  }

  function updateProfileTypeCvCurve(value: number): void {
    dispatch(
      updateEditedTestPhaseProfileType(editedTestPhaseProfileType, {
        cvCurve: findCvCurveById(value),
      }),
    );
  }

  return (
    <FormLayout additionalClass="test-phases__edit-phases-row-profile-type">
      <div className="test-phases__edit-phases-row-profile-type-inputs">
        <div className="test-phases__tested-area test-phases__select">
          <SelectField
            label="CV Kurve *"
            key={editedTestPhaseProfileType?.cvCurve?.id.toString()}
            value={
              editedTestPhaseProfileType.cvCurve
                ? {
                    value: editedTestPhaseProfileType.cvCurve.id.toString(),
                    label: findCvCurveById(
                      editedTestPhaseProfileType.cvCurve.id,
                    )?.name,
                  }
                : undefined
            }
            action={newValue => {
              updateProfileTypeCvCurve(+newValue.value);
            }}
            options={cvCurvesToSelect()}
            name=""
            searchable={true}
            placeholder="z.B. cv42"
            menuPlacement={MenuPlacement.TOP}
          />
        </div>
      </div>
    </FormLayout>
  );
};

const TestPhasesDataDialogFurtherInformationApplication: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedTestPhaseApplication = useSelector<
    AdminState,
    TestPhaseApplicationWithOptionalId
  >(state => state.adminFacade.editedTestPhaseApplication!);
  const editedTestPhaseSeries = useEditedTestPhaseSeries();

  if (!editedTestPhaseApplication) {
    return null;
  }

  function updateMaxSashWeight(value: number | null): void {
    dispatch(
      updateEditedTestPhaseApplication(editedTestPhaseApplication, {
        maxSashWeight: value,
      }),
    );
  }

  function applicationSetInCertificate(value: boolean): void {
    dispatch(
      updateEditedTestPhaseApplication(editedTestPhaseApplication, {
        inCertificate: value,
      }),
    );
  }

  function updateWidthToHeightRelation(value: number | null): void {
    dispatch(
      updateEditedTestPhaseApplication(editedTestPhaseApplication, {
        maxWidthToHeightRelation: value,
      }),
    );
  }

  return (
    <FormLayout additionalClass="test-phases__edit-phases-row-application">
      <div className="test-phases__edit-phases-row-application-inputs">
        <div className="test-phases__tested-area test-phases__double-field-group">
          <div className="test-phases__title">Flügelgewicht (kg) *</div>
          <InputFieldNumber
            label="Max."
            placeholder="z.B. 42"
            value={editedTestPhaseApplication.maxSashWeight}
            additionalClass="test-phases__input-1"
            onChange={updateMaxSashWeight}
          />
        </div>
        <div className="test-phases__in-certificate">
          <Switch
            labelText="im Zertifikat vorhanden"
            turnedOn={
              editedTestPhaseSeries.inCertificate &&
              editedTestPhaseApplication.inCertificate
            }
            onChange={applicationSetInCertificate}
            disabled={!editedTestPhaseSeries.inCertificate}
          />
        </div>
        {editedTestPhaseApplication.application === Application.SIDE_HUNG && (
          <div className="test-phases__tested-area test-phases__double-field-group">
            <div className="test-phases__title">Seite/Höhe</div>
            <InputFieldNumber
              label="Max."
              placeholder="z.B. 42"
              value={editedTestPhaseApplication.maxWidthToHeightRelation}
              additionalClass="test-phases__input-1"
              onChange={updateWidthToHeightRelation}
            />
          </div>
        )}
      </div>
    </FormLayout>
  );
};

interface EditTestPhasesRowProps {
  activeSection: ActiveFurtherInformationUiSection;
  setActiveSection: (section: ActiveFurtherInformationUiSection) => void;
}

const EditTestPhasesRow: FC<React.PropsWithChildren<EditTestPhasesRowProps>> = (
  props: EditTestPhasesRowProps,
) => {
  return (
    <div className="test-phases__edit-phases-row">
      {props.activeSection === ActiveFurtherInformationUiSection.SYSTEM && (
        <TestPhasesDataDialogFurtherInformationSystems />
      )}

      {props.activeSection === ActiveFurtherInformationUiSection.SERIES && (
        <TestPhasesDataDialogFurtherInformationSeries />
      )}
      {props.activeSection ===
        ActiveFurtherInformationUiSection.OPENING_DIRECTION && <></>}
      {props.activeSection ===
        ActiveFurtherInformationUiSection.APPLICATION && (
        <TestPhasesDataDialogFurtherInformationApplication />
      )}
      {props.activeSection ===
        ActiveFurtherInformationUiSection.PROFILE_TYPE && (
        <TestPhasesDataDialogFurtherInformationProfileType />
      )}
    </div>
  );
};

interface AdminTestPhaseDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
}

const AdminTestPhaseDialog: FC<
  React.PropsWithChildren<AdminTestPhaseDialogProps>
> = (props: AdminTestPhaseDialogProps) => {
  const editableTestPhase = useEditedTestPhase();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const mandatoryFieldsFilledOrInactive = useSelector<AdminState>(
    state =>
      state.adminFacade.editedTestPhaseValid ||
      state.adminFacade.editedTestPhase?.active === ACTIVE_STATE.INACTIVE,
  );

  function generateTestPhase(): void {
    dispatch(createTestPhase());
  }

  function editTestPhase(): void {
    if (!editableTestPhase) {
      return;
    }
    dispatch(changeTestPhase(editableTestPhase));
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        editableTestPhase?.id
          ? `Prüfphasephase ${editableTestPhase.name} bearbeiten`
          : 'Neue Prüfphasephase anlegen'
      }
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilledOrInactive
          ? () => {
              editableTestPhase.id ? editTestPhase() : generateTestPhase();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: editableTestPhase?.id
          ? UiConstants.SAVE
          : UiConstants.ADD,
      }}
    >
      <TestPhasesDataDialogTabNav />
      {editableTestPhase && (
        <Routes>
          <Route path="/basic" element={<TestPhasesDataDialogBasic />} />
          <Route
            path="/further-information/*"
            element={<TestPhasesDataDialogFurtherInformation />}
          />
        </Routes>
      )}
    </Dialog>
  );
};

const TestPhasesDataView: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const testPhases = useSelector<AdminState, TestPhase[]>(
    s => s.adminFacade.testPhases,
  );
  const testPhasesRef = useRef<TestPhase[] | undefined>();

  if (!testPhasesRef.current) {
    testPhasesRef.current = testPhases;
  }

  if (testPhasesRef.current !== testPhases) {
    testPhasesRef.current = testPhases;
  }

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    testPhases,
    searchString,
    ['name'],
  );

  function enableEditTestPhase(): void {
    setDialogIsShown(true);
  }

  function getCurrentTableContent(): TestPhase[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function triggerCreationMode(): void {
    dispatch(initializeNewEditableTestPhase());
    setDialogIsShown(true);
  }

  function setDialogIsShownWithReset(dialogIsShown: boolean): void {
    if (!dialogIsShown) {
      dispatch(updateEditedTestPhaseSystem(undefined));
      dispatch(updateEditedTestPhaseSeries(undefined));
      dispatch(updateEditedTestPhaseSeriesOpeningDirection(undefined));
      dispatch(updateEditedTestPhaseApplication(undefined));
      dispatch(updateEditedTestPhaseMountingType(undefined));
      dispatch(updateEditedTestPhaseProfileType(undefined));
    }

    setDialogIsShown(dialogIsShown);
  }

  return (
    <div>
      <AdminTestPhaseDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShownWithReset}
      />

      <div className="sub-header">
        <div className="sub-header__title">Prüfphasen</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Prüfphasen suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <div>
        <Table subNavigation={false}>
          <TableHeader>
            <th>Name</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(testPhase => (
              <TableRow key={testPhase.id}>
                <td>{testPhase.name}</td>
                <td>
                  <button
                    onClick={() => {
                      enableEditTestPhase();
                      dispatch(selectEditableTestPhase(testPhase));
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                  <button
                    className="test-phases__copy-button"
                    onClick={() => {
                      if (
                        confirm(
                          `Soll die Prüfphase "${testPhase.name}" wirklich kopiert werden?`,
                        )
                      ) {
                        dispatch(copyTestPhaseFacade(testPhase.id));
                      }
                    }}
                  >
                    Kopieren
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </div>
  );
};

export default TestPhasesDataView;
