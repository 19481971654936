import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  preSelectConsoleSet,
  selectConsoleSet,
  switchPage,
} from '../../../redux/uiStateActions';
import { Pages } from '../../../redux/uiStateReducer';
import ParameterValue from '../../Parameters/ParameterValue';
import {
  LockingDrive,
  RangeOfApplication,
  TabOptions,
} from '../../../redux/constants';
import { AnyAction } from 'redux';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { ValueKey } from '../../../redux/valueKey';
import Button, { ButtonSize, ButtonType } from '../../../elements/Button';
import ConsoleDetails from './ConsoleDetails';

function DriveConsoleDetailsColumn() {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const highlightedConsoleSet = useSelector<IState, ConsoleSet | undefined>(
    s => s.ui.highlightedConsoleSet,
  );
  const highlightedConsoleSetConsolesSection = useSelector<
    IState,
    number | undefined
  >(s => s.ui.highlightedConsoleSetConsolesSection);
  const rangeOfApplication = useSelector<IState, ParameterValue>(
    s => s.parameters.rangeOfApplication,
  );
  const numberOfPreselectedConsoleSets = useSelector<IState, number>(
    s => s.ui.preselectedConsoleSets.length,
  );
  const preselectedConsoleSets = useSelector<IState, ConsoleSet[]>(
    s => s.ui.preselectedConsoleSets,
  );
  const suitableConsoleSets = useSelector<IState, ConsoleSet[][]>(
    s => s.staticData.suitableConsoleSets,
  );
  const selectedConsoleSets = useSelector<IState, ConsoleSet[]>(
    s => s.ui.selectedConsoleSets || [],
  );
  const isLockingConsoleSelected = useSelector<IState, boolean>(
    s => s.ui.selectedLockingConsole !== undefined,
  );
  const lockingConsoleRequired = useSelector<IState, boolean>(
    s => s.parameters.lockingDrive.value === LockingDrive.VLD,
  );

  function allConsoleSetsSelected() {
    if (suitableConsoleSets.length === 1) {
      return !!selectedConsoleSets?.[0];
    }
    return selectedConsoleSets?.[0] && selectedConsoleSets?.[1];
  }

  return (
    <ConsoleDetails
      requiredSpace={
        <>
          {rangeOfApplication.value !== RangeOfApplication.ROOF && (
            <div>
              <div className="console-details__description-small-headline">
                <FormattedMessage id="TYPE_ASSEMBLY_SPACE" />
              </div>
              <div>
                <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_FRAME" />{' '}
                {highlightedConsoleSet?.requiredSpaceFrame} mm
              </div>
              <div>
                <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_SASH_FRAME" />{' '}
                {highlightedConsoleSet?.requiredSpaceSashFrame} mm
              </div>
            </div>
          )}
        </>
      }
      headline="CONSOLE_SET_BYLINE"
      highlightedConsolePresent={!!highlightedConsoleSet}
      consoleName={highlightedConsoleSet?.name}
      descriptionKey={highlightedConsoleSet?.descriptionKey}
      hints={highlightedConsoleSet?.hints}
      buttonAction={
        !allConsoleSetsSelected()
          ? () => {
              if (suitableConsoleSets.length === 1) {
                dispatch(
                  selectConsoleSet([highlightedConsoleSet!], {
                    recalculateDrives: true,
                  }),
                );
                if (isLockingConsoleSelected || !lockingConsoleRequired) {
                  dispatch(switchPage(Pages.SELECTED_PRODUCTS));
                }
                // For roof free entry is not available. If the user can click this button it means she has entered profile data,
                // The user does not switch actively to profile entry on the console selectio page. This ensures that profile entry is
                // selected, if the profile data has been used to find a console.
                if (rangeOfApplication.value === RangeOfApplication.ROOF) {
                  dispatch(
                    changeCalculationParameter(
                      ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
                      TabOptions.OPTION_PROFILE_ENTRY,
                      true,
                    ),
                  );
                }
              } else if (
                numberOfPreselectedConsoleSets !== 2 &&
                highlightedConsoleSetConsolesSection !== undefined
              ) {
                dispatch(
                  preSelectConsoleSet(
                    highlightedConsoleSet!,
                    highlightedConsoleSetConsolesSection,
                  ),
                );
              } else {
                dispatch(
                  selectConsoleSet(preselectedConsoleSets, {
                    recalculateDrives: true,
                  }),
                );
                if (isLockingConsoleSelected || !lockingConsoleRequired) {
                  dispatch(switchPage(Pages.SELECTED_PRODUCTS));
                }
              }
            }
          : undefined
      }
      buttonLabel={
        numberOfPreselectedConsoleSets === 2
          ? 'CONFIRM_CONSOLE_SELECTION'
          : 'SELECT_CONSOLE'
      }
      primaryButton={
        numberOfPreselectedConsoleSets === 2 ? (
          <Button
            action={() => {
              dispatch(
                selectConsoleSet(preselectedConsoleSets, {
                  recalculateDrives: true,
                }),
              );
              dispatch(switchPage(Pages.SELECTED_PRODUCTS));
            }}
            additionalClass="console-details__confirm-button"
            type={ButtonType.PRIMARY}
            label="CONFIRM_CONSOLE_SELECTION"
            iconName="add"
            size={ButtonSize.LARGE}
          />
        ) : undefined
      }
      qaPrefix="select-console-set-button"
    />
  );
}

export default DriveConsoleDetailsColumn;
