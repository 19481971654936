import React, { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetConsoleSets,
  resetLockingConsoles,
  switchPage,
} from '../../../redux/uiStateActions';
import './ConsoleColumn.scss';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import DownloadDialogButton from '../../DownloadDialogButton';
import classNames from 'classnames';
import { ReactComponent as IconRemove } from '../../../IconRemove.svg';
import Button, {
  ButtonPosition,
  ButtonSize,
  ButtonType,
} from '../../../elements/Button';
import { LoggedInUser, Pages } from '../../../redux/authenticationReducer';
import {
  getSelectedWindow,
  useSelectedDrive,
} from '../../../hooks/selectorHooks';
import { AnyAction } from 'redux';
import { onEnter } from '../../../lib/utils';
import { LockingConsole } from '../../../redux/admin/adminFacadeReducer';
import { LockingDrive } from '../../../redux/constants';
import LockingConsoleDetails from '../ConsoleDetailsColumn/LockingConsoleDetails';

function SingleConsoleDetails(props: {
  consoleSet: ConsoleSet;
  headline: ReactElement;
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="console-column__console-column__description-section">
      <div className="console-column__title">
        <div className="console-column__description-small-headline console-details__title-byline">
          {props.headline}
        </div>
        <div className="console-column__description-name">
          {props.consoleSet.name}
        </div>
      </div>
      <div className="console-column__description-text-container">
        <div
          className="console-column__description-text"
          dangerouslySetInnerHTML={{
            __html: formatMessage({ id: props.consoleSet.descriptionKey }),
          }}
        />

        <div className="console-column__assembly-data">
          <div className="console-column__description-small-headline">
            <FormattedMessage id="TYPE_ASSEMBLY_SPACE" />
          </div>
          <div data-qa="required-space-frame">
            <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_FRAME" />{' '}
            {props.consoleSet.requiredSpaceFrame} mm
          </div>
          <div data-qa="required-space-sash-frame">
            <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_SASH_FRAME" />{' '}
            {props.consoleSet.requiredSpaceSashFrame} mm
          </div>
        </div>
        {!props.consoleSet.active && (
          <div className="drive-details-column__errors">
            <div className="drive-details-column__description-errors-headline">
              <FormattedMessage id="ERROR_MESSAGES" />
            </div>
            <div
              key="DRIVE_NOT_SOLD_ANY_MORE"
              className="drive-details-column__errors-items"
            >
              <FormattedMessage id="DRIVE_NOT_SOLD_ANY_MORE" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function SelectedConsoleSets() {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedConsoleSets = useSelector<IState, ConsoleSet[] | undefined>(
    s => {
      const selectedWindow = getSelectedWindow(s);
      return selectedWindow
        ? selectedWindow.consoleSets
        : s.ui.selectedConsoleSets;
    },
  )!;
  const selectedDrive = useSelectedDrive();
  const currentUser = useSelector<IState, LoggedInUser>(
    state => state.authentication.currentUser!,
  );
  const selectedLockingConsole = useSelector<
    IState,
    LockingConsole | undefined
  >(s => s.ui.selectedLockingConsole);
  const isVLD = useSelector<IState, boolean>(
    s => s.parameters.lockingDrive.value === LockingDrive.VLD,
  );

  function resetConsoleSetsListener(): void {
    dispatch(resetConsoleSets());
    dispatch(resetLockingConsoles());
  }

  function reloadWindow() {
    window.location.reload();
  }

  return (
    <>
      <div
        className="console-column__vertical-button vertical-button vertical-button--remove"
        onClick={resetConsoleSetsListener}
        onKeyDown={onEnter(resetConsoleSetsListener)}
        tabIndex={0}
      >
        <div className="vertical-button__title">
          <FormattedMessage id="DESELECT_SELECTION" />
        </div>
        <div className="vertical-button__icon-container">
          <IconRemove />
        </div>
      </div>
      <div className="console-column__description">
        {!selectedConsoleSets?.length && (
          <div className="console-column__add-drive-console-button">
            <div className="console-column__CTA-headline">
              <FormattedMessage id="ADD_CONSOLE_ANIMATION_TEXT" />
            </div>
            <Button
              label="ADD_CONSOLE"
              iconName="add"
              action={() => dispatch(switchPage(Pages.CONSOLE_CALCULATION))}
              type={ButtonType.PRIMARY}
              position={ButtonPosition.CENTER}
              size={ButtonSize.LARGE}
              qaId="add-console-button"
            />
          </div>
        )}

        {selectedConsoleSets && selectedConsoleSets.length > 0 && (
          <>
            <SingleConsoleDetails
              consoleSet={selectedConsoleSets[0]}
              headline={
                selectedConsoleSets.length === 1 ? (
                  <FormattedMessage id="CONSOLE_SET_BYLINE" />
                ) : (
                  <FormattedMessage id="CONSOLE_RESULTS_DRIVE_ONE" />
                )
              }
            />
            {selectedConsoleSets.length === 2 && (
              <SingleConsoleDetails
                consoleSet={selectedConsoleSets[1]}
                headline={<FormattedMessage id="CONSOLE_RESULTS_DRIVE_TWO" />}
              />
            )}
          </>
        )}
        {selectedLockingConsole && selectedDrive?.includedConsoleSet && (
          <div className="console-column__no-console-message">
            <FormattedMessage id="CONSOLE_COLUMN_MESSAGE_CONSOLE_INCLUDED" />
          </div>
        )}
        {selectedLockingConsole && (
          <LockingConsoleDetails console={selectedLockingConsole} />
        )}
        {isVLD && !selectedLockingConsole && (
          <div className="console-column__add-locking-console-button">
            <div className="console-column__CTA-headline">
              <FormattedMessage id="ADD_LOCKING_CONSOLE_ANIMATION_TEXT" />
            </div>
            <Button
              label="ADD_CONSOLE"
              iconName="add"
              action={() => dispatch(switchPage(Pages.CONSOLE_CALCULATION))}
              type={ButtonType.PRIMARY}
              position={ButtonPosition.CENTER}
              size={ButtonSize.LARGE}
              qaId="add-console-button"
            />
          </div>
        )}
        {(!isVLD ||
          (selectedConsoleSets?.length && selectedLockingConsole)) && (
          <div className="console-column__CTA">
            <div className="console-column__CTA-headline">
              <FormattedMessage id="CONSOLE_COLUMN_DOWNLOAD_ANIMATION" />
            </div>
            {selectedDrive && (
              <DownloadDialogButton
                selectedDrive={selectedDrive}
                primary={true}
                showConsoleDocuments={true}
                showWindDeflectorDocuments={false}
              />
            )}

            {!currentUser && (
              <button
                className="console-column__reload-action"
                onClick={() => reloadWindow()}
              >
                <FormattedMessage id="NEW_CALCULATION" />
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
}

function AddConsoleCTA() {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const currentUser = useSelector<IState, LoggedInUser>(
    state => state.authentication.currentUser!,
  );

  function reloadWindow() {
    window.location.reload();
  }

  return (
    <div className="console-column__CTA">
      <div className="console-column__CTA-headline">
        <FormattedMessage id="ADD_CONSOLE_ANIMATION_TEXT" />
      </div>
      <Button
        label="ADD_CONSOLE"
        iconName="add"
        action={() => dispatch(switchPage(Pages.CONSOLE_CALCULATION))}
        type={ButtonType.PRIMARY}
        position={ButtonPosition.CENTER}
        size={ButtonSize.LARGE}
        qaId="add-console-button"
      />

      {!currentUser && (
        <button
          className="console-column__reload-action"
          onClick={() => reloadWindow()}
        >
          <FormattedMessage id="NEW_CALCULATION" />
        </button>
      )}
    </div>
  );
}

interface DriveConsoleIncludedMessageProps {
  className?: string;
}

function DriveConsoleIncludedMessage({
  className,
}: DriveConsoleIncludedMessageProps) {
  return (
    <div
      className={classNames('console-column__no-console-message', className)}
    >
      <FormattedMessage id="CONSOLE_COLUMN_MESSAGE_CONSOLE_INCLUDED" />
    </div>
  );
}

function ConsoleColumn() {
  const selectedConsoleSets = useSelector<IState, ConsoleSet[] | undefined>(
    s => {
      const selectedWindow = getSelectedWindow(s);
      return selectedWindow
        ? selectedWindow.consoleSets
        : s.ui.selectedConsoleSets;
    },
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedDrive = useSelectedDrive();
  const selectedLockingConsole = useSelector<
    IState,
    LockingConsole | undefined
  >(s => s.ui.selectedLockingConsole);

  const isVLD = useSelector<IState, boolean>(
    s => s.parameters.lockingDrive.value === LockingDrive.VLD,
  );
  if (selectedDrive?.includedConsoleSet && !isVLD) {
    return (
      <div className="console-column">
        <DriveConsoleIncludedMessage />
      </div>
    );
  }

  if (selectedDrive?.includedConsoleSet && isVLD && !selectedLockingConsole) {
    return (
      <div className="console-column">
        <DriveConsoleIncludedMessage className="console-column__no-console-message--select-console" />
        <div className="console-column__CTA console-column__CTA--with_vld">
          <div className="console-column__CTA-headline">
            <FormattedMessage id="ADD_LOCKING_CONSOLE_ANIMATION_TEXT" />
          </div>
          <Button
            label="ADD_CONSOLE"
            iconName="add"
            action={() => dispatch(switchPage(Pages.CONSOLE_CALCULATION))}
            type={ButtonType.PRIMARY}
            position={ButtonPosition.CENTER}
            size={ButtonSize.LARGE}
            qaId="add-console-button"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('console-column', {
        'console-column--console-selected':
          selectedConsoleSets?.length || selectedLockingConsole,
      })}
    >
      {selectedConsoleSets?.length || selectedLockingConsole ? (
        <SelectedConsoleSets />
      ) : (
        <AddConsoleCTA />
      )}
    </div>
  );
}

export default ConsoleColumn;
